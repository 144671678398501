import React from "react"

import Container from 'react-bootstrap/Container'
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container className="py-5 text-center">
      <h1 className="text-primary">NOT FOUND</h1>
      <p>The requested page does not exist. Please use the navigation above for valid pages.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
